import React from "react";
import classes from "./page-title.module.scss";
import classNames from "classnames";

const PageTitle = function ({ title, description, page = "home" }) {
    return (
        <div className={classNames(
            classes["title"],
            classes[page]
        )}>
            <h1>{title}</h1>
            <p>
                Doručenie od <span className={classes["price"]}>7,00 €</span>
            </p>
        </div>
    );
};

export default PageTitle;
